<template>
  <div class="defalut">
    <div class="cor-select">
      <div class="cor-select-left">
        <el-select
          v-model="className"
          placeholder="请选择"
          @change="changeClass"
        >
          <el-option
            v-for="item in classList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="cor-select-right">
        未做学期初测试请先做学期初测试，做完后方可做学期末测试，且学期的前两个月只能做学期初测试
      </div>
    </div>
    <div class="cor-content">
      <div class="chek-tec">
        <div class="chek-tec-head">
          <h2 class="chek-title">选择教师</h2>
        </div>
        <ul class="chek-content-list" v-if="tecData.length > 0">
          <li
            v-for="(item, index) in tecData"
            :key="index"
            @click="checkedOneTec(item.id)"
          >
            <input
              type="checkbox"
              :checked="fruitIdCec.indexOf(item.id) >= 0"
              name="checkboxinput"
              class="input-checkbox"
            />
            <div class="chek-content-con">
              <img
                src="../../../../assets/img/online/Icon_Tea_C.png"
                alt=""
                v-if="fruitIdCec.indexOf(item.id) >= 0"
              />
              <img
                v-else
                src="../../../../assets/img/online/icon_teacher.png"
                alt=""
              />
              <div class="chek-name" :title="item.name">{{ item.name }}</div>
            </div>
          </li>
        </ul>
        <ul class="chek-content-list" v-else>
          <li>
            <div class="class-tec-con-item">
              <img
                src="../../../../assets/img/school/Icon_Teacher_Ks.png"
                alt=""
              />
              <p class="tec-name">暂无教师</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="chek-stu">
        <div class="chek-tec-head">
          <h2 class="chek-title">选择幼儿</h2>
          <div class="chek-btn">
            <p class="chek-all" @click="checkedAll">
              <img src="../../../../assets/img/school/Icon_alls.png" alt="" />
              全选
            </p>
            <p class="chek-del" @click="checkedCan">
              <img
                src="../../../../assets/img/school/Icon_Stu_Clean.png"
                alt=""
              />
              清空
            </p>
          </div>
        </div>
        <ul class="chek-content-list" v-if="stuData.length > 0">
          <li
            v-for="(item, index) in stuData"
            :key="index"
            @click="checkedOne(item.id)"
          >
            <input
              type="checkbox"
              :checked="fruitIds.indexOf(item.id) >= 0"
              name="checkboxinput"
              class="input-checkbox"
            />
            <div class="chek-content-list-con">
              <img
                src="../../../../assets/img/online/Icon_Stu_C_b.png"
                alt=""
                v-if="fruitIds.indexOf(item.id) >= 0"
              />
              <img
                src="../../../../assets/img/online/icon_stu.png"
                alt=""
                v-else
              />
              <div class="stu-name" :title="item.name">{{ item.name }}</div>
            </div>
          </li>
        </ul>
        <ul class="chek-content-list" v-else>
          <li class="stu-class-bg-null">
            <img src="../../../../assets/img/school/Icon_Stud_Ks.png" alt="" />
            <p>暂无幼儿</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="cor-btn">
      <div class="firstBtn" v-if="semesterStatus[0]" @click="toTestSta(0)">
        学期初测试
      </div>
      <div class="endBtn" v-if="semesterStatus[1]" @click="toTestSta(1)">
        学期末测试
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gradeId: "",
      btn: [
        {
          name: "学期初测试",
          value: "2",
          class: "firstBtn",
          comp: "semesterFirst",
        },
        {
          name: "学期末测试",
          value: "3",
          class: "endBtn",
          comp: "semesterEnd",
        },
      ],
      className: "",
      // 初始化全选按钮, 默认不选
      isCheckedAll: false,
      fruitIds: [], // 学生
      fruitIdCec: [], // 老师
      tecData: [],
      stuData: [],
      classList: [],
      value: "",
      semesterStatus: [], //学期初、学期末状态
    };
  },
  mounted() {
    this.getClassList();
  },
  methods: {
    //  获取上课班级
    getClassList() {
      let data = {
        teacherId: this.$store.state.userInfo.teacherId,
      };
      this.api.nAdmin.findGradesOfTid(data).then((res) => {
        console.log(res, "班级列表");
        this.classList = res.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          };
        });
        this.className = res.data[0].name;
        this.gradeId = res.data[0].id;
        //this.getYDList(res.data[0].id, res.data[0].type);
        this.findTestGrade(res.data[0].id);
      });
    },
    bindRou(comp) {
      this.$emit("currentComp", comp);
    },
    checkedOne(id) {
      console.log(id);
      let idIndex = this.fruitIds.indexOf(id);
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        this.fruitIds.splice(idIndex, 1);
      } else {
        // 选中该checkbox
        this.fruitIds.push(id);
        if (this.fruitIds.length == this.stuData.length) {
          this.isCheckedAll = true;
        } else {
          this.isCheckedAll = false;
        }
      }
    },
    checkedOneTec(id) {
      let idIndex = this.fruitIdCec.indexOf(id);
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        this.fruitIdCec.splice(idIndex, 1);
      } else {
        // 选中该checkboxf
        this.fruitIdCec.push(id);
      }
    },
    checkedAll() {
      this.isCheckedAll = !this.isCheckedAll;
      // 全选时
      this.fruitIds = [];
      this.stuData.forEach(function (fruit) {
        this.fruitIds.push(fruit.id);
      }, this);
    },
    checkedCan() {
      this.fruitIds = [];
    },
    // 获取老师和学员信息
    findTestGrade(id) {
      console.log(id);
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: id,
      };
      this.api.pes.findTestGrade(data).then((res) => {
        this.gradeType = res.data.atteGrade.type;
        this.tecData = res.data.atteTeachers;
        this.stuData = res.data.atteStudents;
        this.semesterStatus = res.data.semesterStatus;
      });
    },
    // 跳转初
    toTestSta(item) {
      if (this.fruitIdCec.length <= 0) {
        return this.$notice({
          message: "请选择教师",
        }).isShow();
      } else if (this.fruitIds.length <= 0) {
        return this.$notice({
          message: "请选择幼儿",
        }).isShow();
      } else {
        this.$router.push({
          path: "semesterFirst",
          query: {
            gradeId: this.gradeId,
            gradeType: this.gradeType,
            isEnd: item,
            province: this.$store.state.userInfo.provinces,
            schoolId: this.$store.state.userInfo.schoolId,
            semesterId: this.$store.state.userInfo.semesterId,
            teacherId: this.fruitIdCec.join(","),
            type: 0,
            stuIds: this.fruitIds.join(","),
          },
        });
      }
    },

    changeClass(val) {
      this.gradeId = val;
      this.findTestGrade(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.cor-select {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  .cor-select-left {
    width: 130px;
    height: 38px;
    /deep/ .el-input__inner {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      border-radius: 6px;
    }
  }
  .cor-select-right {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ba3037;
    opacity: 0.9;
    margin-top: 12px;
  }
}
.cor-content {
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
  border-radius: 2px;
  padding: 30px;
  .chek-tec {
    .chek-title {
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #333333;
    }
    .chek-content-list {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      .input-checkbox {
        left: 75px;
      }
      img {
        width: 53px;
        height: 53px;
      }
      li {
        margin-right: 14px;
        margin-bottom: 14px;
        width: 100px;
        text-align: center;
        position: relative;
        text-align: center;
        .chek-name {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .chek-stu {
    .chek-tec-head {
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      .chek-title {
        font-size: 18px;
      }
    }
    .chek-btn {
      display: flex;
      margin-left: 10px;
    }
    .chek-all {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 22px;
      background: linear-gradient(232deg, #41559a, #7093d9);
      box-shadow: 0px 3px 6px 0px rgba(113, 146, 255, 0.33);
      font-size: 13px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      border-radius: 30px;
      margin-right: 20px;
      cursor: pointer;
      img {
        margin-right: 4px;
      }
    }
    .chek-del {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 20px;
      background: #ffffff;
      border: 1px solid #3a4b83;
      font-size: 13px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #3b4c84;
      border-radius: 30px;
      cursor: pointer;
      img {
        margin-right: 4px;
      }
    }
    .chek-content-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      li {
        margin-right: 14px;
        margin-bottom: 14px;
        width: 100px;
        text-align: center;
        position: relative;
        padding-bottom: 10px;
        cursor: pointer;
        .input-checkbox {
          left: 75px;
        }
        img {
          width: 53px;
          height: 53px;
        }
        .stu-name {
          // font-size:14px;
          // width: 42px;
          // height: 42px;
          // background: linear-gradient(232deg, #b93037, #ef525b);
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #333333;
          // opacity: 0.3;
          // border-radius: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.cor-btn {
  display: flex;
  margin-top: 25px;
  .firstBtn {
    width: 130px;
    height: 38px;
    background: linear-gradient(232deg, #b93037, #ef525b);
    box-shadow: 0px 2px 6px 0px rgba(255, 67, 77, 0.2);
    color: #fff;
    border-radius: 6px;
    text-align: center;
    line-height: 38px;
    font-size: 18px;
    cursor: pointer;
    a {
      color: #fff;
    }
  }
  .firstBtn:hover {
    background: linear-gradient(232deg, #e04b57, #ff616e);
  }
  .endBtn:hover {
    background: linear-gradient(232deg, #3c549f, #6590dc);
  }
  .endBtn {
    width: 130px;
    height: 38px;
    background: linear-gradient(232deg, #2e3c6b, #4f64ac);
    box-shadow: 0px 3px 6px 0px rgba(113, 146, 255, 0.33);
    border-radius: 5px;
    color: #fff;
    text-align: center;
    line-height: 38px;
    font-size: 18px;
    margin-left: 20px;
    cursor: pointer;
    a {
      color: #fff;
    }
  }
}
input[type="checkbox"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: none;
  zoom: 1;
  outline: none;
  -webkit-appearance: none; /*去除默认样式*/
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  background: url("../../../../assets/img/online/icon_UnC.png") no-repeat center
    center;
}
input[type="checkbox"]:checked {
  background: url("../../../../assets/img/online/icon_choose.png") no-repeat
    center center;
}
.pad-box-content {
  padding: 0 10px;
}
.chek-content-list {
  display: flex;
  flex-wrap: wrap;
}
</style>